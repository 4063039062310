import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import classNames from "classnames";

async function fetchFB() { // args: url, method, params
  await window.fbLoaded;
  return new Promise(resolve => window.FB.api.apply(window.FB.api, Array.from(arguments).concat([resolve])));
}

function MediaList({ media }) {
  return (
    <div className="row">
      {media.map((item, i) => (
        <div key={i}>
          <img src={item.media_url} alt="" width="150" />
        </div>
      ))}
    </div>
  );
}

function MediaInsights({ item }) {
  const [insights, setInsights] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await fetchFB(`/${item.id}/insights?metric=engagement,impressions`);
      console.log('Insights response is', response);
      setInsights(response.data);
    }
    fetchData();
  }, [item.id]);

  return (
    <div>
      <table className="table">
        <tbody>
          {insights.map((insight, i) => (
            <tr key={i}>
              <th>
                {insight.description}
              </th>
              <td>
                {insight.values.length > 0 ? insight.values[0].value : `Not available`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function MediaWithInsightsList({ media }) {
  const [selectedMedia, setSelectedMedia] = useState(null);

  return (
    <div className="row">
      <div className="col-8">
        {media.map((item, i) => {
          const selected = selectedMedia && item.id === selectedMedia.id;
          return (
            <div key={i} style={{ display: "inline-block", margin: "10px", cursor: "pointer",
              border: `3px solid ${selected ? 'rgb(195, 230, 203)' : 'rgba(1, 1, 1, 0)'}`,
              borderRadius: "5px"
            }} onClick={() => setSelectedMedia(item)}>
              <img src={item.media_url} alt="" width="150" />
            </div>
          );
        })}
      </div>
      <div className="col-4">
        <div className="alert alert-secondary">
          {selectedMedia ? <MediaInsights item={selectedMedia} /> : <div>
            Select a piece of media to the left to view insights!
          </div>}
        </div>
      </div>
    </div>
  );
}

function DisplayRecentMedia({ igUser }) {
  const [media, setMedia] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const mediaResponse = await fetchFB(`/${igUser.id}/media`, 'get', {
        fields: 'id,media_type,media_url,thumbnail_url'
      });
      console.log('Media is', mediaResponse);
      setMedia(mediaResponse.data);
    }
    fetchData();
  }, [igUser.id]);

  return (
    <>
      <div className="row">
        <div className="alert alert-secondary">
          Displaying recent media from @{igUser.username}
        </div>
      </div>
      <MediaWithInsightsList media={media} />
    </>
  );
}

function DisplayRecentHashtag({ igUser }) {
  const [media, setMedia] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const hashtagResponse = await fetchFB(`/ig_hashtag_search?user_id=${igUser.id}&q=fidogs`);
      const hashtagId = hashtagResponse.data[0].id;
      const mediaResponse = await fetchFB(`/${hashtagId}/recent_media?fields=media_url,children{media_url}&user_id=${igUser.id}`);
      // Posts without a media_url are carousels.
      setMedia(mediaResponse.data.filter(m => !!m.media_url));
    }
    fetchData();
  }, [igUser.id]);

  return (
    <>
      <div className="row">
        <div className="alert alert-secondary">
          Displaying recent media from #fidogs
        </div>
      </div>
      <MediaList media={media} />
    </>
  );
}

function DisplayRecentTag({ igUser }) {
  const [media, setMedia] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const mediaResponse = await fetchFB(`/${igUser.id}/tags?fields=media_url,children{media_url}&user_id=${igUser.id}`);
      if (mediaResponse.data) {
        // Posts without a media_url are carousels.
        setMedia(mediaResponse.data.filter(m => !!m.media_url));
      }
    }
    fetchData();
  }, [igUser.id]);

  return (
    <>
      <div className="row">
        <div className="alert alert-secondary">
          Displaying recent tags of this page
        </div>
      </div>
      <MediaList media={media} />
    </>
  );
}

function Dashboard() {
  const [igUser, setIGUser] = useState(null);
  const [mode, setMode] = useState("media");

  useEffect(() => {
    async function fetchData() {
      const pages = await fetchFB('/me/accounts');
      console.log('Pages are', pages);
      const pageId = pages.data[0].id;
      const businessAccount = await fetchFB(`/${pageId}?fields=instagram_business_account{username}`);
      console.log('Business account is', businessAccount);
      setIGUser(businessAccount.instagram_business_account);
    }
    fetchData();
  }, []);

  const onLogout = async () => {
    await new Promise(resolve => window.FB.logout(resolve));
    window.location.reload();
  };

  let bodyComponent = null;
  if (igUser) {
    if (mode === "media") {
      bodyComponent = <DisplayRecentMedia igUser={igUser} />;
    } else if (mode === "hashtag") {
      bodyComponent = <DisplayRecentHashtag igUser={igUser} />;
    } else if (mode === "tags") {
      bodyComponent = <DisplayRecentTag igUser={igUser} />;
    }
  }

  const buttonClassForMode = (thisMode) => classNames('btn', mode === thisMode ? 'btn-primary' : 'btn-secondary');

  return (
    <>
      <div className="row mb-2">
        <div className="col-6">
          <div className="btn-group" role="group">
            <button type="button" className={buttonClassForMode('media')} onClick={() => setMode('media')}>
              View recent media from page
            </button>
            <button type="button" className={buttonClassForMode('hashtag')} onClick={() => setMode('hashtag')}>
              View #fidogs hash tag
            </button>
            <button type="button" className={buttonClassForMode('tags')} onClick={() => setMode('tags')}>
              View recent tags of this page
            </button>
          </div>
        </div>
        <div className="col-2">
          <span className="btn btn-outline-secondary" onClick={onLogout}>
            Log out
          </span>
        </div>
      </div>
      <div className="row mb-2">
      </div>
      {bodyComponent}
    </>
  )
}

function Home() {
  const [connected, setConnected] = useState(false);
  useEffect(() => {
    async function checkLoginState() {
      await window.fbLoaded;
      window.FB.getLoginStatus(function (response) {
        setConnected(response.status === "connected");
      });
    }
    window.checkLoginState = checkLoginState;
    checkLoginState();
  }, []);

  return (
    <>
      <div className="row">
        <h1>Fi Business Dashboard</h1>
      </div>
      {!connected ? (
        <div
          className="fb-login-button"
          data-width=""
          data-size="medium"
          data-button-type="login_with"
          data-auto-logout-link="false"
          data-use-continue-as="false"
          data-onlogin="checkLoginState()"
          data-scope="instagram_basic,pages_show_list,pages_read_engagement,pages_read_user_content,instagram_manage_insights,instagram_manage_comments"
        />
      ) : (
        <Dashboard />
      )}
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="container">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
